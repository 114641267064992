var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.back },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "keyword-cen-hull" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-field", {
                attrs: {
                  rows: "1",
                  autosize: "",
                  type: "textarea",
                  maxlength: "100",
                  autofocus: "",
                  "show-word-limit": "",
                  placeholder: "请输入关键字, 多个使用空格分隔",
                },
                on: { input: _vm.addKeyWordFn },
                model: {
                  value: _vm.message,
                  callback: function ($$v) {
                    _vm.message = $$v
                  },
                  expression: "message",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "keyword-label" },
        _vm._l(_vm.messagearr, function (item, index) {
          return _c("div", { key: "label" + index }, [
            _c("div", { staticClass: "keyword-label-item" }, [
              _vm._v(_vm._s(item) + " "),
              _c(
                "div",
                {
                  staticClass: "keyword-label-item-icon",
                  on: {
                    click: function ($event) {
                      return _vm.keywordLabelCloseFn(index)
                    },
                  },
                },
                [_c("van-icon", { attrs: { name: "cross" } })],
                1
              ),
            ]),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "change-case-button" },
        [
          _c(
            "van-button",
            {
              attrs: { plain: "", color: "#fff", type: "primary" },
              on: { click: _vm.vanBtnClearFn },
            },
            [
              _c("span", { staticStyle: { color: "#999999" } }, [
                _vm._v("清除"),
              ]),
            ]
          ),
          _c(
            "van-button",
            {
              attrs: { type: "primary", color: "#009FFC" },
              on: { click: _vm.vanBtnRetrieveFn },
            },
            [
              _c("span", { staticStyle: { color: "#FFF" } }, [
                _vm._v("重新检索"),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }