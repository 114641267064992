<template>
  <div>
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="back"
                 size="small"
                 type="primary">
        返回
      </fb-button>
    </fb-header>
    <div class="keyword-cen-hull">
      <van-cell-group>
        <van-field v-model="message"
                   rows="1"
                   autosize
                   type="textarea"
                   maxlength='100'
                   @input="addKeyWordFn"
                   autofocus
                   show-word-limit
                   placeholder="请输入关键字, 多个使用空格分隔" />
      </van-cell-group>
    </div>
    <!-- 标签 -->
    <div class="keyword-label">
      <div v-for="(item,index) in messagearr"
           :key="'label'+index">
        <div class="keyword-label-item">{{item}}
          <div class="keyword-label-item-icon"
               @click="keywordLabelCloseFn(index)">
            <van-icon name="cross" />
          </div>
        </div>
      </div>
    </div>
    <div class="change-case-button">
      <van-button @click="vanBtnClearFn"
                  plain
                  color='#fff'
                  type="primary"><span style="color:#999999">清除</span></van-button>
      <van-button @click="vanBtnRetrieveFn"
                  type="primary"
                  color="#009FFC"><span style="color:#FFF">重新检索</span></van-button>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'keyword',
  data() {
    return {
      title: '',
      message: '', // 文字
      messagearr: [] // 转化成数组
    }
  },
  computed: {
    ...mapState('ana', ['anaTwoKeyDown'])
  },
  mounted() {
    if (this.anaTwoKeyDown) {
      this.message = this.anaTwoKeyDown.join(',').replace(/,/g, ' ')
    }
  },
  methods: {
    back() {
      if (
        this.directGoNative ||
        window.nativeEnterIndex >= this.$router.length
      ) {
        console.log('直接返回到原生端')
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    ...mapMutations('ana', ['SET_ANATWOKEYDOWN']),
    vanBtnClearFn() {
      console.log('清除')
      this.message = ''
      this.messagearr = []
      this.SET_ANATWOKEYDOWN('')
    },
    vanBtnRetrieveFn() {
      console.log('重新检索')
      this.$router.go(-1)
    },
    ...mapMutations('ana', ['SET_ANATWOKEYDOWN']),
    // 输入事件
    addKeyWordFn() {
      const data = this.message.split(' ') // 分割
      var r = data.filter(function(s) {
        return s && s.trim()
      })
      console.log(r)
      this.messagearr = r
      console.log(this.messagearr)
      this.SET_ANATWOKEYDOWN(r) // 存入
      console.log(this.anaTwoKeyDown)
    },
    keywordLabelCloseFn(index) {
      console.log(index)
      this.messagearr.splice(index, 1)
      this.message = this.messagearr.join(',').replace(/,/g, ' ')
    }
  }
}
</script>
<style scope>
.keyword-cen-hull {
  padding: 45px 0;
}
.change-case-button {
  display: flex;
  margin-top: 45px;
}
.change-case-button > button:nth-child(1) {
  flex-grow: 4;
}
.change-case-button > button:nth-child(2) {
  flex-grow: 6;
}
.keyword-label {
  display: flex;
  flex-wrap: wrap;
}
.keyword-label-item {
  color: #00a2fc;
  border: 1px solid #00a2fc;
  border-radius: 5px;
  flex-grow: 1;
  margin: 5px 5px;
  padding: 2px 15px;
  padding-right: 20px;
  font-size: 16px;
  position: relative;

  flex: 0 0 25%;
}
.keyword-label-item-icon {
  position: absolute;
  top: 3px;
  right: 2px;
}
</style>
